<template>
  <ant-upload-dragger
    v-model:fileList="fileList"
    :accept="uploadCtlr.accept"
    :action="uploadCtlr.action"
    :method="uploadCtlr.method"
    :max-count="uploadCtlr.maxCount"
    :headers="uploadCtlr.headers"
    :data="uploadCtlr.customData"
    :progress="uploadCtlr.progress"
    :before-upload="uploadCtlr.beforeUpload"
    :multiple="multiple"
    :disabled="disabled"
    :show-upload-list="true"
    list-type="picture-card"
    :is-image-url="() => false"
    @change="uploadCtlr.handleChange"
    @reject="uploadCtlr.handleReject"
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
    @preview="previewCtrl.handlePreview"
  >
    <template #iconRender="{ file }">
      <custom-icon :file="file" :ctrl="previewCtrl" :size="30" />
    </template>
    <template #previewIcon="{ file }">
      <eye-outlined v-if="file.thumbUrl" />
      <eye-invisible-outlined v-else />
    </template>
    <div v-if="isDraggingOver" class="drop-area">
      <p class="icon-container">
        <ph-arrow-line-down class="icon" />
      </p>
      <p>
        {{ i18nProvider.translate('i18n_upload_area_drop_here', locale) }}
      </p>
    </div>
    <div v-else class="upload-area">
      <p class="icon-container">
        <ph-upload-simple class="icon" />
      </p>
      <p>
        {{ i18nProvider.translate('i18n_upload_area_click_or_drop_files', locale) }}
      </p>
    </div>
  </ant-upload-dragger>
  <preview :ctrl="previewCtrl" />
</template>

<script lang="ts" setup>
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { PhArrowLineDown, PhUploadSimple } from '@phosphor-icons/vue';
import type { UploadFile } from 'ant-design-vue';
import { UploadDragger as AntUploadDragger } from 'ant-design-vue';
import { ref } from 'vue';
import type { WidgetProps } from '../../../generated/widgetTypes';
import { SupportedLocales, i18nProvider } from '../../i18n';
import CustomIcon from './FileIcon.vue';
import Preview from './Preview.vue';
import { PreviewController } from './previewController';
import { UploadController } from './uploadController';

const props = defineProps<{
  value: string[];
  errors: string[];
  locale: SupportedLocales;
  acceptedFormats?: string[];
  acceptedMimeTypes: string;
  maxFileSize?: number | null;
  multiple?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'file-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const previewCtrl = new PreviewController();

const uploadCtlr = new UploadController(
  emit,
  props.value,
  previewCtrl.fileThumbnail,
  props.locale,
  props.acceptedFormats,
  props.acceptedMimeTypes,
  props.maxFileSize,
  props.multiple,
);

const fileList = ref<UploadFile[]>(uploadCtlr.initialFileList());

const isDraggingOver = ref(false);

const handleDragEnter = () => {
  isDraggingOver.value = true;
};
const handleDragLeave = () => {
  isDraggingOver.value = false;
};
const handleDrop = () => {
  isDraggingOver.value = false;
};
</script>

<style scoped lang="scss">
@import '../../../common/style/widgets.scss';
.icon-container {
  width: 48px;
  height: 48px;
  margin: auto;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: solid;
  border-width: 1px;
}

p {
  color: var(--font-color);
  margin-bottom: 4px;
}

.icon {
  font-size: 22px;
}

.drop-area {
  .icon-container {
    background-color: transparent;
    border-color: var(--color-main);
  }
  .icon {
    color: var(--color-main);
  }
}

.upload-area {
  .icon-container {
    background-color: var(--color-main);
    border-color: var(--color-main);
  }
  .icon {
    color: var(--button-font-color-main);
  }
}
</style>
