<template>
  <ant-upload
    ref="uploadRef"
    :capture="capture"
    v-model:fileList="fileList"
    :accept="uploadCtlr.accept"
    :action="uploadCtlr.action"
    :method="uploadCtlr.method"
    :max-count="uploadCtlr.maxCount"
    :headers="uploadCtlr.headers"
    :data="uploadCtlr.customData"
    :progress="uploadCtlr.progress"
    :before-upload="uploadCtlr.beforeUpload"
    @change="uploadCtlr.handleChange"
    @reject="uploadCtlr.handleReject"
    @preview="previewCtrl.handlePreview"
    :multiple="multiple"
    :disabled="disabled"
    :show-upload-list="true"
    list-type="picture-card"
  >
    <template #iconRender="{ file }">
      <custom-icon :file="file" :ctrl="previewCtrl" :size="20" />
    </template>

    <slot v-if="showUploadUI" name="ui" />
  </ant-upload>
  <preview :ctrl="previewCtrl" />
</template>

<script lang="ts" setup>
import { Upload as AntUpload, UploadFile } from 'ant-design-vue';
import { computed, ref } from 'vue';
import type { WidgetProps } from '../../../generated/widgetTypes';
import CustomIcon from './FileIcon.vue';
import Preview from './Preview.vue';
import { PreviewController } from './previewController';
import { UploadController } from './uploadController';
import { SupportedLocales } from '../../i18n';

const props = defineProps<{
  value: string[];
  errors: string[];
  locale: SupportedLocales;
  acceptedFormats?: string[];
  acceptedMimeTypes: string;
  maxFileSize?: number | null;
  multiple?: boolean;
  disabled?: boolean;
  capture?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'file-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const uploadRef = ref<InstanceType<typeof AntUpload> | null>(null);

const previewCtrl = new PreviewController();

const uploadCtlr = new UploadController(
  emit,
  props.value,
  previewCtrl.fileThumbnail,
  props.locale,
  props.acceptedFormats,
  props.acceptedMimeTypes,
  props.maxFileSize,
  props.multiple,
);

const fileList = ref<UploadFile[]>(uploadCtlr.initialFileList());
const showUploadUI = computed(() => {
  return props.multiple || fileList.value.length === 0;
});

defineExpose({
  uploadRef,
});
</script>

<style scoped lang="scss">
:deep() {
  .ant-upload-select {
    width: 100% !important;
  }
}
</style>
